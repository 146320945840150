import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import { getCanonicalUrl } from '@hultafors/shared/helpers';

import { datoApi } from '@hultafors/hellberg/api';
import {
  GlobalFields,
  LandingPageFragment,
  PageProps,
} from '@hultafors/hellberg/types';

import { prodDomains } from '../next.domains';

const Page = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.Page),
);
const ContentArea = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.ContentArea),
);

export interface LandingPageProps extends PageProps {
  content: LandingPageFragment;
}

export const getStaticProps: GetStaticProps<LandingPageProps> = async ({
  locale,
}) => {
  const revalidate = 60; // 1 minute
  try {
    const dato = datoApi(locale);
    const canonical = getCanonicalUrl(
      locale,
      prodDomains,
      'www.hellbergsafety.com',
    );

    const {
      data: { landingPage: content, ...globalFields },
    } = await dato.getLandingPage();
    return {
      props: {
        ...(globalFields as GlobalFields),
        content,
        canonical,
      },
      revalidate,
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
      revalidate,
    };
  }
};

export const LandingPage: NextPage<LandingPageProps> = ({
  content,
  canonical,
}) => {
  return (
    <Page seo={content.seo} canonical={canonical} testId="landing-page">
      <ContentArea content={content.contentArea} />
    </Page>
  );
};

export default LandingPage;
